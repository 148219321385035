import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getListReplymessage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_replymessage', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getReplymessage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_replymessage', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addReplymessage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/add_replymessage', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editReplymessage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/edit_replymessage', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteReplymessage(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/delete_replymessage/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultReplymessage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_default_replymessage', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
