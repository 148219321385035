<template>
  <b-modal
    id="form-replymessage"
    :visible="shallShowReplymessageFormModal"
    :title="`${$t('Form')} ${$t('Chat Message')}`"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-replymessage-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typeReplymessageForm)} ${$t('Chat Message')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-replymessage-form-modal', false) : false"
        />
        <!-- <feather-icon
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
        /> -->
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typeReplymessageForm !== 'View')"
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ $t('Save Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>
                  <b-col cols="6">

                    <b-row>
                      <!-- Field: Name -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="(typeReplymessageForm === 'Add' || typeReplymessageForm === 'Edit')"
                          :label="$t('Name')"
                          label-for="replymessage-name"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Name')"
                            rules="required"
                          >
                            <b-form-input
                              id="replymessage-name"
                              v-model="replymessageData.name"
                              :state="getValidationState(validationContext)"
                              :disabled="busy"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('Name')"
                          label-for="replymessage-name"
                        >
                          <span class="form-info-box">{{ replymessageData.name }}</span>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <!-- Field: Select Message Type -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="typeReplymessageForm === 'Add'"
                          :label="`${$t('Select Message Type')} (${$t('Chat Message')})`"
                          style="margin-bottom: 0.5rem;"
                        >
                          <b-button
                            v-for="rmType in replymessageData.replymessageType"
                            :key="rmType.value"
                            style="margin-bottom: 0.5rem; margin-right: 0.8rem;"
                            size="sm"
                            :variant="(replymessageData.type === rmType.value)? 'info' : 'outline-info'"
                            @click="onSelectChatMessageType(rmType.value)"
                          >
                            {{ rmType.name }}
                          </b-button>
                        </b-form-group>

                        <b-form-group
                          v-if="typeReplymessageForm === 'Edit'"
                          :label="`${$t('Select Message Type')} (${$t('Chat Message')})`"
                          style="margin-bottom: 0.5rem;"
                        >
                          <b-button
                            v-model="replymessageData.type"
                            style="margin-bottom: 0.5rem; margin-right: 0.8rem;"
                            size="sm"
                            variant="info"
                          >
                            {{ replymessageData.type_name }}
                          </b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row
                      v-if="(typeReplymessageForm === 'Add' || typeReplymessageForm === 'Edit')"
                    >
                      <!-- Field:  -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="replymessageData.type === 'message'"
                          :label="`${$t('Context')}`"
                          label-for="replymessage-context"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Context')"
                            rules="required"
                          >
                            <b-form-textarea
                              v-model="replymessageData.context"
                              rows="1"
                              :placeholder="$t('Context')"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row
                      v-if="(typeReplymessageForm === 'Add' || typeReplymessageForm === 'Edit')"
                      class="mb-1"
                    >
                      <!-- Field: Description -->
                      <b-col
                        cols="12"
                      >
                        <span v-if="replymessageData.type === 'message'">
                          ป้อนบริบทข้อความที่ต้องการให้ตอบกลับ
                        </span>
                      </b-col>
                    </b-row>

                    <b-row>
                      <!-- Field: Status -->
                      <b-col
                        cols="6"
                      >
                        <b-form-group
                          v-if="(typeReplymessageForm === 'Add' || typeReplymessageForm === 'Edit')"
                          :label="$t('Status')"
                          label-for="replymessage-status"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Status')"
                            rules="required"
                          >
                            <b-form-radio
                              v-model="replymessageData.status"
                              name="replymessage-status"
                              value="active"
                              class="custom-control-success cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('Active') }}</span>
                            </b-form-radio>
                            <b-form-radio
                              v-model="replymessageData.status"
                              name="replymessage-status"
                              value="inactive"
                              class="custom-control-info cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('Inactive') }}</span>
                            </b-form-radio>
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('Status')"
                          label-for="replymessage-status"
                        >
                          <span class="form-info-box">
                            <b-badge
                              pill
                              :variant="`light-${isStatusVariant(replymessageData.status)}`"
                              class="text-capitalize"
                            >
                              {{ $t(textFirstUpper(replymessageData.status)) }}
                            </b-badge>
                          </span>
                        </b-form-group>
                      </b-col>
                    </b-row>

                  </b-col>
                  <b-col cols="6">
                    <!-- Select -->

                    <b-row>
                      <!-- Field: Chatmessage -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="(typeReplymessageForm === 'Add' || typeReplymessageForm === 'Edit')"
                          :label="$t('Chat Message')"
                          label-for="replymessage-chatmessage"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Chat Message')"
                            rules="required"
                          >
                            <b-form-input
                              v-model="replymessageData.chatmessage_id"
                              style="display: none;"
                              :state="getValidationState(validationContext)"
                            />
                            <b-input-group class="m-0">
                              <b-input-group-prepend
                                is-text
                                style="cursor: pointer;"
                                @click="selectChatmessage"
                              >
                                <feather-icon
                                  icon="PlusIcon"
                                  size="12"
                                />
                              </b-input-group-prepend>
                              <b-form-input
                                id="replymessage-chatmessage"
                                ref="refChatmessage"
                                v-model="replymessageData.chatmessage_name"
                                :disabled="true"
                                style="background: #f8f8f8 !important;"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('Chat Message')"
                          label-for="replymessage-chatmessage"
                        >
                          <span class="form-info-box">{{ replymessageData.chatmessage_name }}</span>
                        </b-form-group>
                      </b-col>

                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <line-chat-message
                          :data="jsonMessage"
                          @action="onAction"
                        />
                        <div
                          v-if="replymessageData.chatmessage_type === 'flex' && actionData !== null"
                          class="mt-1"
                        >
                          <b-form-group
                            :label="$t('Type')"
                          >
                            <b-form-input
                              v-model="actionData.type"
                            />
                          </b-form-group>
                          <b-form-group
                            :label="$t('Label')"
                          >
                            <b-form-input
                              v-model="actionData.label"
                            />
                          </b-form-group>
                          <b-form-group
                            v-if="actionData.type === 'uri'"
                            :label="$t('URI')"
                          >
                            <b-form-input
                              v-model="actionData.uri"
                            />
                          </b-form-group>
                          <b-form-group
                            v-if="actionData.type === 'message' || actionData.type === 'postback'"
                            :label="$t('Text')"
                          >
                            <b-form-input
                              v-model="actionData.text"
                            />
                          </b-form-group>

                          <b-form-group
                            v-if="actionData.type === 'postback' || actionData.type === 'datetimepicker'"
                            :label="$t('Data')"
                          >
                            <b-form-input
                              v-model="actionData.data"
                            />
                          </b-form-group>
                          <b-form-group
                            v-if="actionData.type === 'datetimepicker'"
                            :label="$t('Mode')"
                          >
                            <b-form-input
                              v-model="actionData.mode"
                            />
                          </b-form-group>
                          <b-form-group
                            v-if="actionData.type === 'datetimepicker'"
                            :label="$t('Initial')"
                          >
                            <b-form-input
                              v-model="actionData.initial"
                            />
                          </b-form-group>
                          <b-form-group
                            v-if="actionData.type === 'datetimepicker'"
                            :label="$t('Max')"
                          >
                            <b-form-input
                              v-model="actionData.max"
                            />
                          </b-form-group>
                          <b-form-group
                            v-if="actionData.type === 'datetimepicker'"
                            :label="$t('Min')"
                          >
                            <b-form-input
                              v-model="actionData.min"
                            />
                          </b-form-group>
                        </div>
                        <div
                          v-if="replymessageData.chatmessage_type === 'imagemap' && actionData !== null"
                          class="mt-1"
                        >
                          <div>
                            <b-tabs>
                              <b-tab
                                v-for="(action, index) in actionData"
                                :key="index"
                                :title="`${$t('Area')} ${index+1}`"
                              >
                                <div
                                  style="border-radius: 0.8rem; border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                                >
                                  <b-form-group
                                    v-if="action.area"
                                    :label="$t('Area')"
                                  >
                                    <div
                                      style="border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                                    >
                                      <b-row>
                                        <b-col
                                          v-for="(value, key) in action.area"
                                          :key="key"
                                        >
                                          <span>{{ key }}</span>
                                          <b-form-input
                                            :value="value"
                                            style="margin-top: 5px;"
                                          />
                                        </b-col>
                                      </b-row>
                                    </div>
                                  </b-form-group>
                                  <b-form-group
                                    v-if="action.type"
                                    :label="$t('Type')"
                                  >
                                    <b-form-input
                                      v-model="action.type"
                                    />
                                  </b-form-group>
                                  <b-form-group
                                    v-if="action.text && action.type === 'message'"
                                    :label="$t('Text')"
                                  >
                                    <b-form-input
                                      v-model="action.text"
                                    />
                                  </b-form-group>
                                  <b-form-group
                                    v-if="action.linkUri && action.type === 'uri'"
                                    :label="$t('Link Uri')"
                                  >
                                    <b-form-input
                                      v-model="action.linkUri"
                                    />
                                  </b-form-group>
                                </div>
                              </b-tab>
                            </b-tabs>
                          </div>
                        </div>
                      </b-col>
                    </b-row>

                  </b-col>
                </b-row>

              </div>
            </b-card>
          </div>

        </b-form>

      </div>

    </validation-observer>

    <!-- Chatmessage Select -->
    <chatmessage-select
      v-model="showChatmessageSelectModal"
      :title="`${$t('Select')} ${$t('Chatmessage')}`"
      :chatmessage-data="chatmessageData"
      @update-chatmessage-select="updateChatmessageSelect"
      @discard-chatmessage-select="discardChatmessageSelect"
    />

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup, BFormInput,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BFormTextarea,
  BTab,
  BTabs,
  BFormRadio,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import LineChatMessage from '@core/components/line-chat-message/LineChatMessage.vue'
import ChatmessageSelect from '@/views/lineapi/chatmessage/ChatmessageSelect.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    LineChatMessage,
    ChatmessageSelect,

    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BFormTextarea,
    BTab,
    BTabs,
    BFormRadio,
    BInputGroup,
    BInputGroupPrepend,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
  },
  model: {
    prop: 'shallShowReplymessageFormModal',
    event: 'update:shall-show-replymessage-form-modal',
  },
  props: {
    shallShowReplymessageFormModal: {
      type: Boolean,
      required: true,
    },
    typeReplymessageForm: {
      type: String,
      default: 'Add',
    },
    replymessageData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      skin: store.state.appConfig.layout.skin,
      jsonMessage: {},
      actionData: null,
      showChatmessageSelectModal: false,
      chatmessageData: {},
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  watch: {
    replymessageData() {
      this.jsonMessage = {}
      this.actionData = null
      this.chatmessageData = {}
      if (this.replymessageData?.chatmessage_id
        && this.replymessageData.chatmessage_id !== '') {
        this.chatmessageData.id = this.replymessageData.chatmessage_id
        this.chatmessageData.name = this.replymessageData.chatmessage_name
        this.chatmessageData.message = this.replymessageData.chatmessage_message
        this.chatmessageData.type = this.replymessageData.chatmessage_type

        if (this.chatmessageData.message !== '' && this.chatmessageData.message !== null) {
          this.actionData = null
          if (this.chatmessageData.type === 'flex') {
            if (this.chatmessageData.message.contents !== '') {
              this.jsonMessage = this.chatmessageData.message
            } else {
              this.jsonMessage = {}
            }
          } else {
            this.jsonMessage = this.chatmessageData.message
          }
          if (this.chatmessageData.type === 'imagemap') {
            const { actions } = this.chatmessageData.message
            this.actionData = actions
          }
        } else {
          this.jsonMessage = {}
        }
      } else {
        this.replymessageData.chatmessage_id = ''
        this.replymessageData.chatmessage_name = ''
        this.replymessageData.chatmessage_type = ''
        this.replymessageData.chatmessage_message = ''
      }
    },
  },
  methods: {
    textFirstUpper(text) {
      return text.slice(0, 1).toUpperCase() + text.slice(1, text.length)
    },
    isStatusVariant(isstatus) {
      if (isstatus === 'active') return 'success'
      if (isstatus === 'inactive') return 'danger'
      return 'danger'
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeReplymessageForm === 'Add') {
        delete this.replymessageData.line_oa_id
        this.replymessageData.lineOaId = this.lineOaId
        store.dispatch('replymessage-store/addReplymessage', this.replymessageData)
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-replymessage-form')
            this.$emit('update:shall-show-replymessage-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeReplymessageForm === 'Edit') {
        store.dispatch('replymessage-store/editReplymessage', { id: this.replymessageData.id, data: this.replymessageData })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-replymessage-form')
            this.$emit('update:shall-show-replymessage-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeReplymessageForm === 'Delete') {
        store.dispatch('replymessage-store/deleteReplymessage', {
          id: this.replymessageData.id,
        })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-replymessage-form')
            this.$emit('update:shall-show-replymessage-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    onDiscard() {
      this.$emit('discard-replymessage-form')
      this.$emit('update:shall-show-replymessage-form-modal', false)
    },
    onErrorSourceJson() {
      // console.log('error')
    },
    onSelectChatMessageType(type) {
      this.replymessageData.type = type
    },
    onAction(action) {
      this.actionData = action
    },
    updateChatmessageSelect(data) {
      this.$nextTick(() => {
        this.replymessageData.chatmessage_id = data.select.id
        this.replymessageData.chatmessage_name = data.select.name
        this.replymessageData.chatmessage_message = data.select.message
        this.replymessageData.chatmessage_type = data.select.type
        this.chatmessageData.id = this.replymessageData.chatmessage_id
        this.chatmessageData.name = this.replymessageData.chatmessage_name
        this.chatmessageData.message = this.replymessageData.chatmessage_message
        this.chatmessageData.type = this.replymessageData.chatmessage_type
        if (this.chatmessageData.message !== '' && this.chatmessageData.message !== null) {
          this.actionData = null
          if (this.chatmessageData.type === 'flex') {
            if (this.chatmessageData.message.contents !== '') {
              this.jsonMessage = this.chatmessageData.message
            } else {
              this.jsonMessage = {}
            }
          } else {
            this.jsonMessage = this.chatmessageData.message
          }
          if (this.chatmessageData.type === 'imagemap') {
            const { actions } = this.chatmessageData.message
            this.actionData = actions
          }
        } else {
          this.jsonMessage = {}
        }

        this.$refs.refForm.focus()
      })
    },
    discardChatmessageSelect() {
    },
    selectChatmessage() {
      if (this.replymessageData.chatmessage_id === '') {
        this.chatmessageData = {}
      } else {
        this.chatmessageData = {}
        this.chatmessageData.id = this.replymessageData.chatmessage_id
        this.chatmessageData.name = this.replymessageData.chatmessage_name
        this.chatmessageData.message = this.replymessageData.chatmessage_message
        this.chatmessageData.type = this.replymessageData.chatmessage_type
      }
      this.showChatmessageSelectModal = true
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
.jsoneditor-poweredBy {
  display: none !important;
}
.jsoneditor {
  border: thin solid #d8d6de !important;
}
.jsoneditor-menu{
  background-color: #aaaaaa !important;
  border-bottom: 1px solid #aaaaaa !important;
}
</style>
